import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { OpcionesPerfilRolDto } from '../data/OpcionesPerfilRolDto';

@Injectable({
  providedIn: 'root'
})
export class SubjectsService {

  public visibleSidebar$ = new BehaviorSubject<boolean>(false);
  public visibleSidebarLogin$ = new BehaviorSubject<boolean>(false);
  public ocultoNotFound$ = new BehaviorSubject<boolean>(false);
  public hideSidebarNavbar$ = new BehaviorSubject<boolean>(false);
  public visibleSidebarInicio$ = new BehaviorSubject<any>(false);
  public moveIconNav$ = new BehaviorSubject<any>(false);
  public iconSidebar$ = new BehaviorSubject<any>(false);
  public resolucionPantallaApp$ = new BehaviorSubject<number>(0);
  public aperturoFiltroMovil$ = new BehaviorSubject<boolean>(false);
  public actualizarDatosNumeroMasJugados = new BehaviorSubject<boolean>(true);
  public actualizarDatosDetalleSucursales = new BehaviorSubject<boolean>(true);
  public fechaFiltrar = new BehaviorSubject<string>('');
  public informacionFiltrar = new BehaviorSubject<string>('');
  public habilitarBotonMarcarMensajesLeidos = new BehaviorSubject<boolean>(false);
  public llamarFuncionCantidadMensajes = new BehaviorSubject<boolean>(false);
  public esFechaActual = new BehaviorSubject<boolean>(false);
  public informacionModalOtrosProductos = new BehaviorSubject([]);
  public base64Excel = new BehaviorSubject<string>('');
  public esVisibleReportePdf = new BehaviorSubject<boolean>(false);
  public esVisiblePorRedireccion = new BehaviorSubject<boolean>(false);
  public datosRolUsuario = new BehaviorSubject<OpcionesPerfilRolDto>({});
  public pathVistaToSideBar$ = new BehaviorSubject<string>('');
  public pathTituloInformativo$ = new BehaviorSubject<string>('');
  public visualizadorPdf = new BehaviorSubject({});
  public noInfoDetalleSucursales = new Subject<boolean>();
  public obtenerNombre = new Subject<void>();
  private optenerSesion = new Subject<any>();
  public dataOptenerSesion$ = this.optenerSesion.asObservable();

  constructor() { }

  // COM Sidebar.
  public set listadoRecursosAsideData(visibleSidebar: boolean) {
    this.visibleSidebar$.next(visibleSidebar);
  }

  public get listadoRecursosAside(): Observable<boolean> {
    return this.visibleSidebar$.asObservable();
  }
  // COM Sidebar oculto en el login
  public set ocultarSidebarLoginSet(visibleSidebarLogin: boolean) {
    this.visibleSidebarLogin$.next(visibleSidebarLogin);
  }

  public get ocultarSidebarLoginGet(): Observable<boolean> {
    return this.visibleSidebarLogin$.asObservable();
  }
  // COM Ocultar sidebar en notFound
  public set ocultoNotFoundSet(ocultoNotFound: boolean) {
    this.ocultoNotFound$.next(ocultoNotFound);
  }

  public get ocultoNotFoundGet(): Observable<boolean> {
    return this.ocultoNotFound$.asObservable();
  }

  // COM Validar que sidebar y nabar no se oculte con la redireccion lodgeado desde reportes php
  public set hideSidebarNavbarSet(hideSidebarNavbar: boolean) {
    this.hideSidebarNavbar$.next(hideSidebarNavbar);
  }

  public get hideSidebarNavbarGet(): Observable<boolean> {
    return this.hideSidebarNavbar$.asObservable();
  }
  // COM Sidebar oculto en el inicio
  public set ocultarSidebarInicioSet(visibleSidebarInicio: any) {
    this.visibleSidebarInicio$.next(visibleSidebarInicio);
  }

  public get ocultarSidebarInicioGet(): Observable<any> {
    return this.visibleSidebarInicio$.asObservable();
  }
  // COM Mover icono navbar
  public set moveIconNavSet(moveIconNav: any) {
    this.moveIconNav$.next(moveIconNav);
  }

  public get moveIconNavGet(): Observable<any> {
    return this.moveIconNav$.asObservable();
  }
  // COM Icon sidebar
  public set iconSidebarSet(iconSidebar: any) {
    this.iconSidebar$.next(iconSidebar);
  }

  public get iconSidebarGet(): Observable<any> {
    return this.iconSidebar$.asObservable();
  }
  // COM Resize.
  public set resolucionPantallaData(resolucionPantallaApp: number) {
    this.resolucionPantallaApp$.next(resolucionPantallaApp);
  }

  public get resolucionPantalla(): Observable<number> {
    return this.resolucionPantallaApp$.asObservable();
  }


  // COM Actualizar Fecha Filtro.
  public set fechaFiltrarSet(actualizarHoraFiltro: string) {
    this.fechaFiltrar.next(actualizarHoraFiltro);
  }

  public get fechaFiltrarGet(): Observable<string> {
    return this.fechaFiltrar.asObservable();
  }

  // COM Informacion filtros.
  public set informacionFiltroBuscarSet(informacionFiltro: string) {
    this.informacionFiltrar.next(informacionFiltro);
  }

  public get informacionFiltroBuscarGet(): Observable<string> {
    return this.informacionFiltrar.asObservable();
  }

  // COM Llamar funcion de navbar desde otro componente.
  public set llamarFuncionCantidadMensajesSet(llamar: boolean) {
    this.llamarFuncionCantidadMensajes.next(llamar);
  }

  public get llamarFuncionCantidadMensajesGet(): Observable<boolean> {
    return this.llamarFuncionCantidadMensajes.asObservable();
  }

  // COM Validar si la fecha es actual
  public set esFechaActualSet(esFechaActual: boolean) {
    this.esFechaActual.next(esFechaActual);
  }

  public get esFechaActualGet(): Observable<boolean> {
    return this.esFechaActual.asObservable();
  }

  // COM Base 64 download excel
  public set base64ExcelSet(base64: string) {
    this.base64Excel.next(base64);
  }

  public get base64ExcelGet(): Observable<string> {
    return this.base64Excel.asObservable();
  }

  //COM capturar rol para el sidebar
  public set datosRolUsuarioSet(datosRolUsuario: any) {
    this.datosRolUsuario.next(datosRolUsuario);
  }

  public get datosRolUsuarioGet() {
    return this.datosRolUsuario.asObservable();
  }

  // COM Actualizar Fecha Filtro.
  public set pathVistaToSideBarSet(pathVistaToSideBar: string) {
    this.pathVistaToSideBar$.next(pathVistaToSideBar);
  }

  public get pathVistaToSideBarGet(): Observable<string> {
    return this.pathVistaToSideBar$.asObservable();
  }

  // COM Visualizador Pdf
  public set visualizadorPdfSet(visualizadorPdf: any) {
    this.visualizadorPdf.next(visualizadorPdf);
  }

  public get visualizadorPdfGet(): Observable<any>{
    return this.visualizadorPdf.asObservable();
  }

  public set esVisibleReportePdfData(esVisibleReportePdf: boolean) {
    this.esVisibleReportePdf.next(esVisibleReportePdf);
  }

  public get esVisibleReportePdfGet(): Observable<boolean> {
    return this.esVisibleReportePdf.asObservable();
  }

  //  COM Ejecutar funcion para obtener nombre de usuario en el navbar despues de logueado
  obtenerNombrefuncion(): void {
    this.obtenerNombre.next();
  }

  onObtenerNombrefuncion(): Observable<void> {
    return this.obtenerNombre.asObservable();
  }

  // COM Obtener sesion en el navbar despues de loguearse
  sendOptenerSesion(data: boolean) {
    this.optenerSesion.next(data);
  }

   // COM Opcion de titulo informativo
   public set pathTituloInformativoSet(pathTituloInformativo: string) {
    this.pathTituloInformativo$.next(pathTituloInformativo);
  }

  public get pathTituloInformativoGet(): Observable<string> {
    return this.pathTituloInformativo$.asObservable();
  }
}
