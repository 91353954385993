import { Component } from '@angular/core';

@Component({
  selector: 'app-block-temp',
  styles: [`
    :host {
      text-align: center;
      color: #ffff;
      --primario: #17479e;
    --secundario: #fce300;
    --blanco: #fff;
    --gris: #b7bdd4;
    --fuentePrincipal: 'Helvetica Neue', Arial, sans-serif;
    --fuenteSecundaria: 'Helvetica Neue Bold', Arial, sans-serif;
    }
    html,body{
	background:#000;
	margin:0;
}
.centered{
	width:400px;
	height:400px;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	-moz-transform:translate(-50%,-50%);
	-o-transform:translate(-50%,-50%);
	-ms-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}
.label{
	position:absolute;
	top:50%;
  font-size: 1rem;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	-moz-transform:translate(-50%,-50%);
	-o-transform:translate(-50%,-50%);
	-ms-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}
.blob-1,.blob-2{
	width:70px;
	height:70px;
	position:absolute;
	background:var(--primario);
	-moz-border-radius:50%;
	-webkit-border-radius:50%;
	border-radius:50%;
	top:50%;
  	left:50%;
	-webkit-transform:translate(-50%,-50%);
	-moz-transform:translate(-50%,-50%);
	-o-transform:translate(-50%,-50%);
	-ms-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}

.blob-1{
	left:80%;
	animation:osc-l 3s ease infinite;
}
.blob-2{
	left:80%;
	animation:osc-r 3s ease infinite;
	background: var(--secundario);
}

@keyframes osc-l{
	0%{left:80%;}
	50%{left:20%;}
	100%{left:80%; z-index: 1;}
}
@keyframes osc-r{
	0%{left:20%;}
	50%{left:80%;}
	100%{left:20%; z-index: -1;}
}
  `],
  template: `
  <div class = "centered">
	<div class = "blob-1"></div>
  <div class="label">Cargando</div>
	<div class = "blob-2"></div>
</div>
  `
})
export class BlockTemplateComponent { }
