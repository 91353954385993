import * as fromAutenticacionActions from './autenticacion.actions';
import { initialState, AutenticacionState } from './autenticacion.model';
import { Action, createReducer, on } from '@ngrx/store';


const methodReducer = createReducer(
  initialState,
  on(fromAutenticacionActions.addSession, (state, { _newSession }) => ({
    ...state,
    ss: _newSession
  })),
  on(fromAutenticacionActions.removeSession, (state) => ({
    ...state,
    ss: {}
  })),
  on(fromAutenticacionActions.addOpcionesUsuario, (state, { newOpciones }) => ({
    ...state,
    ou: newOpciones
  })),
  on(fromAutenticacionActions.removeOpcionesUsuario, (state) => ({
    ...state,
    ou: {}
  })),
  on(fromAutenticacionActions.addPerfil, (state, { newPerfil }) => ({
    ...state,
    pr: newPerfil
  })),
  on(fromAutenticacionActions.removePerfil, (state) => ({
    ...state,
    pr: {}
  })),

);


// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: AutenticacionState | undefined, action: Action) {
  return methodReducer(state, action);
}








