
import { createAction, props } from '@ngrx/store';
import { ValidarUsuarioDto } from '../dto/validarUsuarioDto';
import { SessionDto } from '../../../core/data/SessionDto';
import { OpcionesPerfilRolDto } from 'src/app/core/data/OpcionesPerfilRolDto';

// COM Session****
export const addSession = createAction(
  '[ADD SS] ',
  props<{ _newSession: SessionDto }>()
);

export const removeSession = createAction(
  '[REMOVE SS]',
);

// COM Perfiles****
export const addPerfil = createAction(
  '[ADD PR]',
  props<{ newPerfil: OpcionesPerfilRolDto }>()
);

export const removePerfil = createAction(
  '[REMOVE PR]',
);

// COM Opciones usuario****
export const addOpcionesUsuario = createAction(
  '[ADD OU]',
  props<{ newOpciones: any }>()
);

export const removeOpcionesUsuario = createAction(
  '[REMOVE OU]',
);









