import { constantes } from 'src/app/core/data/Constantes';
import appVersion from '../../package.json';

// // COM ENDPOINT ->>>>> Pruebas *************************************
const api = 'https://desarrollo-backendadminpaganar.todomas.do';
const caribbeanRedireccion = 'http://pruebas.caribbeants.com:8080/CaribbeanAdmin/app.login.php';


const keyCryptoJS = '8atgTPCb7wH0wyDdp0Gf&!iJ3IWr8atgT';
// COM Keys store
const keyHome = 'ADM_1001_RD';
const keyMonitoreo = 'ADM_1002_RD';
const keyEmpresa = 'ADM_1003_RD';
const keyLoterias = 'ADM_1004_RD';
const keyOtrosProductos = 'ADM_1005_RD';
const keySeguridad = 'ADM_1006_RD';
const keyAdministracion = 'ADM_1007_RD';
const keyReportes = 'ADM_1008_RD';
const keyCorreos = 'ADM_1009_RD';
const keyAutenticacion = 'ADM_1010_RD';


export const environment = {
  production: true,
  hmr: false,
  keyCryptoJS,
  api,
  keyHome,
  keyMonitoreo,
  keyEmpresa,
  keyLoterias,
  keyOtrosProductos,
  keySeguridad,
  keyAdministracion,
  keyReportes,
  keyCorreos,
  keyAutenticacion,
  appVersion: appVersion.version,
  caribbeanRedireccion,
  blacklistedRoutes: [
    constantes.LOGIN_ADMINISTRATIVO
  ]
};
