import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DxButtonModule, DxDataGridModule } from 'devextreme-angular';
import { NotFoundComponent } from './shared/layoutComponents/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'autenticacion' },
  {
    path: 'autenticacion', loadChildren: () =>
      import('./feature/autenticacion/autenticacion.module').then(mod => mod.AutenticacionModule)
  },
  {
    path: 'inicio', loadChildren: () =>
      import('./feature/inicio/inicio.module').then(mod => mod.InicioModule),
      // canActivate: [AuthGuard]
  },
  {
    path: 'empresa', loadChildren: () =>
      import('./feature/empresa/empresa.module').then(mod => mod.EmpresaModule),
  },
  {
    path: 'reportes', loadChildren: () =>
      import('./feature/reportes/reportes.module').then(mod => mod.ReportesModule),
  },
  {
    path: 'util', loadChildren: () =>
      import('./shared/shared.module').then(mod => mod.SharedModule),
    data: { nombre: 'util' }
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    DxDataGridModule,
    DxButtonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})


export class AppRoutingModule { }
