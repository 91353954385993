import { ChangeDetectorRef, Component, Injector, Input, OnInit, forwardRef } from '@angular/core';
import { UtilService } from '../../../core/services/util.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SweetAlertService } from '../../../core/services/sweet-alert.service';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-campo-fecha',
  templateUrl: './campo-fecha.component.html',
  styleUrls: ['./campo-fecha.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CampoFechaComponent),
      multi: true
    }
  ]
})
export class CampoFechaComponent implements ControlValueAccessor {

  public currectValue!: any;
  public sweetAlertService: SweetAlertService;
  public utilService: UtilService;
  public cdr: ChangeDetectorRef;
  onChange?: (fecha: any) => void;
  onTouch?: () => void;



  constructor(private readonly injector: Injector) {
    this.sweetAlertService = injector.get<SweetAlertService>(SweetAlertService);
    this.utilService = injector.get<UtilService>(UtilService);
    this.cdr = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
  }

  writeValue(value: any): void {
    if (value) {
      this.currectValue = {...this.currectValue, ...value};
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  public fechaSeleccionada(event: Event): void {

    const fechaSeleccionada = this.utilService.formatDateParamPipe(
      (event.target as HTMLInputElement).value
    );
    const esFechaMayorALaActual =
      this.utilService.validarFechaSeleccionada(fechaSeleccionada);
    const mensaje = `La fecha seleccionada ${fechaSeleccionada} es mayor a actual.`;

    if (esFechaMayorALaActual === true) {
      this.sweetAlertService.sweetAlertInformativo(mensaje);
      this.currectValue = this.utilService.formatDatePipe();
      this.cdr.detectChanges();
      this.cdr.markForCheck();
      this.onTouch();
      this.onChange?.(this.currectValue);
    } else {
      this.onTouch();
      this.onChange?.(fechaSeleccionada);
    }
  }

}
