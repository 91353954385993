import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeFunction'
})
export class PipeFunction implements PipeTransform {
  public transform(value: any, handler: (val: any) => any): any {
    return handler(value);
  }
}
