import { Injectable } from '@angular/core';
import { Toaster } from 'ngx-toast-notifications';


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  //  'success', 'danger', 'warning', 'info', 'primary', 'secondary', 'dark', 'light'

  constructor(
    private readonly toaster: Toaster) { }

  public toastMensajeSinLeer(texto: string): void {
    const alert = 'light';
    this.toaster.open({
      text: texto,
      caption: `Administrativo Todo+`,
      type: alert,
      preventDuplicates: true,
      duration: 10000
    });
  }

  public toastMensajeCampoRequerido(texto: string): void {
    const alert = 'danger';
    this.toaster.open({
      text: texto,
      caption: `Administrativo Todo+`,
      type: alert,
      preventDuplicates: true,
      duration: 4000
    });
  }

  public toastMensajeRespuestaExitosa(texto: string): void {
    const alert = 'success';
    this.toaster.open({
      text: texto,
      caption: `Administrativo Todo+`,
      type: alert,
      preventDuplicates: true,
      duration: 10000
    });
  }
}
