import { Component, HostListener, OnDestroy, ViewChild, ElementRef, Renderer2, AfterViewInit, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { SubjectsService } from 'src/app/core/services/subjects.service';
import { environment } from 'src/environments/environment';
import { constantes } from 'src/app/core/data/Constantes';

const toggleMenuElement = document.getElementById('toggle-menu');
const mainMenuElement = document.getElementById('main-menu');

@Component({
  selector: 'app-nav-bar-landing',
  templateUrl: './nav-bar-landing.component.html',
  styleUrls: ['./nav-bar-landing.component.css']
})
export class NavBarLandingComponent implements OnDestroy {

  @ViewChild('animacionuno', { static: false }) public mydiv: ElementRef;

  private subs = new SubSink();

  public installEvent = null;
  public visible: boolean;
  public resize$?: Observable<number>;
  public resize?: number;
  public contador = 0;
  public esVisible: boolean;

  constructor(
    private readonly router: Router,
    private rendere: Renderer2,
    private readonly subjectService: SubjectsService) {

    this.validarVisibilidad();
  }

  public validarVisibilidad(): void {
    this.subs.add(this.subjectService.esVisiblePorRedireccion.subscribe({
      next: (esViisble: boolean) => {
        this.esVisible = esViisble;
      }
    }));
  }

  public irAdministrativoPhp(): void {
    window.open(environment.caribbeanRedireccion, '_blank');
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  public onBeforeInstallPrompt(event: Event): void {
    event.preventDefault();
    this.installEvent = event;
  }

  public installByUser(): void {
    if (this.installEvent) {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((resp: any) => {
        if (resp.outcome === 'accepted') {
          this.installEvent = null;
        }
        if (resp.outcome === 'dismissed') {
          this.installEvent = null;
        }
      });
    }
  }

  public cerrarVentanaPWA(): void {
    this.installEvent = null;
  }

  public abrirMenu(): void {

    if (this.contador === 0) {
      this.rendere.addClass(this.mydiv.nativeElement, 'main-menu--show');
      this.contador = 1;
    } else {
      this.rendere.removeClass(this.mydiv.nativeElement, 'main-menu--show');
      this.contador = 0;
    }
  }

  public cerrarMenu(): void {
    this.rendere.addClass(this.mydiv.nativeElement, 'main-menu--show');
    this.contador = 1;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
