<header class="header" #sizeHeader [hidden]="ocultoLogin">
    <div class="contenedor header__contenidoHeader">
        <div class="header__menuHamburguesa">

            <div class="menuHamburguesa_correo" #menuActive>
                <button #hamburger class="hamburger hamburger--arrowalt" type="button" (click)="abrirSidebar()">
                <span class="hamburger-box">
                    <img src="assets/imagenes/icons/icons_sidebar/menuSidebar.svg" alt="Icono abrir y cerrar sidebar" class="hamburger_icon">
                </span>
              </button>
                <!-- <div class="header__badge" (click)="redireccionarMesajeria()">
                    <span class="header__notifyBadge">{{cantidadCorreos}}</span>
                    <img src="../../../../assets/imagenes/icons/email2.png" alt="logo email" rel="tooltip" title="Ir Mensajer&#237;a">
                </div> -->
            </div>
        </div>

        <div class="header__contenedorNombreUsuario">
            
            <div class="nombreUsuario" mat-button [matMenuTriggerFor]="menu">
                <img *ngIf="resize >=768" class="header__iconoUsuario" src="../../../../../assets/imagenes/general/002-user.png" alt="Logo Usuario">
                <h5 class="header__nombreUsuario">{{nombreCompleto}}</h5>
                <img src="assets/imagenes/icons/icons_sidebar/flechaAzulAbajo.svg" alt="Flecha salir" class="header_flecha">
            </div>

        </div>
        <mat-menu #menu="matMenu" class="nombreUsuario_menu">
            <button mat-menu-item (click)="cerrarSession()">
            <img src="assets/imagenes/icons/icons_sidebar/salida.png" alt="Icono de salir" class="icon_salir">
            Cerrar sesión
        </button>
        </mat-menu>

    </div>
</header>