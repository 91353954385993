<div class="contenedor">
    <div class="contenedor-fondo-not-found">
        <div class="titulo-not-found">
            <img class="label-imagen-404" src="../../../../assets/404/404 media.png" alt="imagen de 404">
            <span class="label-mensaje">¡Ups! P&#225;gina no encontrada 😭</span>
            <span class="label-contenido">Lamentamos los inconvenientes. Parece que est&#225;s intentando acceder a una p&#225;gina que fue eliminada, cambi&#243; de nombre o no existe.</span>
            <div class="boton-inicio">
                <button (click)="irInicio()" class="label-boton" type="submit">Regresar al inicio</button>
            </div>
        </div>
    </div>
</div>