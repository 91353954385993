import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromAutenticacionActions from './autenticacion.actions';
import { AutenticacionState } from './autenticacion.model';
import { Observable } from 'rxjs';
import * as fromSelectorAutenticacion from './autenticacion.selectors';
import { ValidarUsuarioDto } from '../dto/validarUsuarioDto';
import { SessionDto } from '../../../core/data/SessionDto';
import { OpcionesPerfilRolDto } from 'src/app/core/data/OpcionesPerfilRolDto';

@Injectable({
  providedIn: 'root'
})
export class AutenticacionStoreService {

  constructor(private store: Store<AutenticacionState>) {
  }

  // COM Session****
  public adicionarSession(_newSession: SessionDto): void {
    this.store.dispatch(
      fromAutenticacionActions.addSession({
        _newSession: { ..._newSession }
      }));
  }

  public removeSession(): void {
    this.store.dispatch(
      fromAutenticacionActions.removeSession());
  }

  // COM Perfil****
  public adicionarPerfil(newPerfil: OpcionesPerfilRolDto): void {
    this.store.dispatch(
      fromAutenticacionActions.addPerfil({
        newPerfil: { ...newPerfil }
      }));
  }

  public removePerfil(): void {
    this.store.dispatch(
      fromAutenticacionActions.removePerfil());
  }

  // COM Opciones usuario****
  public adicionarOpcionesUsuario(newOpciones: any): void {
    this.store.dispatch(
      fromAutenticacionActions.addOpcionesUsuario({
        newOpciones: { ...newOpciones }
      }));
  }

  public removerOpcionesUsuario(): void {
    this.store.dispatch(
      fromAutenticacionActions.removeOpcionesUsuario()
    );
  }

  // COM Selectores ********************
  public obtenerSession$(): Observable<SessionDto> {
    return this.store.select(fromSelectorAutenticacion.selectSession);
  }

  public obtenerPerfiles$(): Observable<OpcionesPerfilRolDto> {
    return this.store.select(fromSelectorAutenticacion.selectPerfil);
  }

  public obtenerOpcionesUsuario$(): Observable<any> {
    return this.store.select(fromSelectorAutenticacion.selectOpcionesUsuario);
  }
}
