import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { MaterialModule } from './libreriasUI/material/material.module';


// COM Componentes ****************
import { NavBarComponent } from './layoutComponents/nav-bar/nav-bar.component';
import { SideBarComponent } from './layoutComponents/side-bar/side-bar.component';
import { NotFoundComponent } from './layoutComponents/not-found/not-found.component';
import { SafeUrlPipe } from '../core/pipes/safe-url.pipe';
import { FooterComponent } from './layoutComponents/footer/footer.component';

import { SwiperModule } from 'swiper/angular';
import { FormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {MatMenuModule} from '@angular/material/menu';

import { CampoFechaComponent } from './custom-components/campo-fecha/campo-fecha.component';
import { FiltroBuscarComponent } from './custom-components/filtro-buscar/filtro-buscar.component';
import { CarouselComponent } from './layoutComponents/carousel/carousel.component';
import { NavBarLandingComponent } from './layoutComponents/nav-bar-landing/nav-bar-landing.component';
import { TituloInformativoOpcionesComponent } from './layoutComponents/titulo-informativo-opciones/titulo-informativo-opciones.component';
import { VisualizadorPdfComponent } from './layoutComponents/visualizador-pdf/visualizador-pdf.component';


@NgModule({
  declarations: [
    NavBarComponent,
    SideBarComponent,
    NotFoundComponent,
    SafeUrlPipe,
    FooterComponent,
    CampoFechaComponent,
    FiltroBuscarComponent,
    NavBarLandingComponent,
    CarouselComponent,
    TituloInformativoOpcionesComponent,
    VisualizadorPdfComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    SwiperModule,
    FormsModule,
    MatMenuModule,
    PdfViewerModule
  ],
  exports: [
    NavBarComponent,
    NavBarLandingComponent,
    SideBarComponent,
    NotFoundComponent,
    SafeUrlPipe,
    FooterComponent,
    CampoFechaComponent,
    FiltroBuscarComponent,
    CarouselComponent,
    TituloInformativoOpcionesComponent,
    VisualizadorPdfComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
