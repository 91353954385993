import { Component, OnInit, ViewEncapsulation } from '@angular/core';


// import Swiper core and required modules
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination]);

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom,

})
export class CarouselComponent  {

  constructor() { }



}
