import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AutenticacionStoreService } from '../../feature/autenticacion/store/autenticacion-store.service';
import { SweetAlertService } from '../services/sweet-alert.service';
import { LoginRequestService } from '../services/login-request.service';

import { SessionDto } from '../data/SessionDto';
import { constantes } from '../data/Constantes';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  public opcionesUsuario$: Observable<SessionDto>;
  public opcionesUsuario: SessionDto;
  public validoSession: boolean;

  constructor(
    private readonly sweetAlertService: SweetAlertService,
    private readonly loginRequestService: LoginRequestService,
    private readonly autenticacionStoreService: AutenticacionStoreService,
    private router: Router) {
    this.opcionesUsuario$ = this.autenticacionStoreService.obtenerOpcionesUsuario$();
    this.opcionesUsuario = new SessionDto();
    this.isAuthenticatedUser();
  }

  public redirect(flag: boolean): any {
    if (!flag) {
      this.sweetAlertService.sweetAlertCierreSesionGuardian();
    }
  }

  async isAuthenticatedUser(): Promise<boolean> {
    this.opcionesUsuario$.subscribe({
      next: (store: SessionDto) => {
        this.opcionesUsuario = store;
      }
    });
    return this.loginRequestService.validarSessionActiva(this.opcionesUsuario?.codigousuario, this.opcionesUsuario?.session).toPromise();
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isAuthenticated: any = await this.isAuthenticatedUser();

    if (['INT01'].includes(isAuthenticated.payload.code)){
      this.sweetAlertService.sweetAlertCierreSesionGuardian();
    }else{
      this.redirect(isAuthenticated.payload);
      return isAuthenticated.payload;
    }
  }

}


