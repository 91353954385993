import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAutenticacionModel from './autenticacion.model';

export const selectAutenticacionoState = createFeatureSelector<fromAutenticacionModel.AutenticacionState>(
  fromAutenticacionModel.autenticacionFeatureKey
);


export const selectSession = createSelector(
  selectAutenticacionoState,
  (state: fromAutenticacionModel.AutenticacionState) => state?.ss);

export const selectPerfil = createSelector(
  selectAutenticacionoState,
  (state: fromAutenticacionModel.AutenticacionState) => state?.pr);

export const selectOpcionesUsuario = createSelector(
  selectAutenticacionoState,
  (state: fromAutenticacionModel.AutenticacionState) => state?.ou);
