
import { environment } from 'src/environments/environment';
import { ValidarUsuarioDto } from '../dto/validarUsuarioDto';
import { SessionDto } from '../../../core/data/SessionDto';
import { OpcionesPerfilRolDto } from 'src/app/core/data/OpcionesPerfilRolDto';

export const autenticacionFeatureKey = environment.keyAutenticacion;

export interface AutenticacionState {
  ss: SessionDto;
  pr: OpcionesPerfilRolDto;
  ou: any;
}

export const initialState: AutenticacionState = {
  ss: {},
  ou: {},
  pr: {}
};


