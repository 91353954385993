<div class="pdf__botones" *ngIf="esVisibleHerramientas">
    <div class="pdf__imprimir">
      <button class="pdf_botonImprimir" (click)="imprimirPdf()" type="button" matTooltip="Imprimir reporte"
        matTooltipPosition="above" aria-label="Bot&#243;n que permite imprimir reporte PDF"></button>
    </div>
    <div class="pdf__descargar">
      <button class="pdf_botonDescargar" (click)="descargarPdf()" type="button" matTooltip="Descargar reporte"
        matTooltipPosition="above" aria-label="Bot&#243;n que permite descargar reporte PDF"></button>
    </div>
    <div class="btn_zoom">
      <button type="button" class="btn_zoomPdf"  (click)="zoomOut()"><span
          class="material-symbols-outlined">zoom_out</span></button>
    </div>
    <div class="btn_zoom">
      <button type="button" class="btn_zoomPdf"  (click)="zoomIn()"><span
          class="material-symbols-outlined">zoom_in</span></button>
    </div>
  </div>
  <div id="print" class="pdf-container" *ngIf="visualizarPdf">
    <!-- https://www.npmjs.com/package/ng2-pdf-viewer -->
    <pdf-viewer [src]="pdfBlob" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false" [zoom]="zoom" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true" [render-text-mode]="2" [external-link-target]="'blank'" [autoresize]="true"
    [show-borders]="false" class="viewer"></pdf-viewer>
  </div>