<swiper [loop]="true" [autoHeight]="true" [allowTouchMove]="false" [autoplay]="{ delay: 1000, disableOnInteraction: false }" [pagination]="{ clickable: true }" [navigation]="true">
    <ng-template data-swiper-autoplay="6000" swiperSlide>
        <img src="https://grupo.todomas.do/wp-content/uploads/2022/04/Copia-de-Enmascarar-grupo-11.png" alt="promocional uno" />
    </ng-template>

    <ng-template data-swiper-autoplay="10000" class="swiper-slide" swiperSlide>
        <img src="https://grupo.todomas.do/wp-content/uploads/2022/04/Enmascarar-grupo-11.png" alt="promocional dos" />
    </ng-template>

</swiper>
