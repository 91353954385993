import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { UsuarioDto } from 'src/app/feature/autenticacion/data/UsuarioDto';
import { constantes } from '../data/Constantes';
import { AutenticacionStoreService } from 'src/app/feature/autenticacion/store/autenticacion-store.service';
import { SessionDto } from '../data/SessionDto';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorsService implements HttpInterceptor {
  public usuarioToken$: Observable<any>;
  public token?: string = '';

  constructor(private autenticacionStoreService: AutenticacionStoreService) {
    this.usuarioToken$ = this.autenticacionStoreService.obtenerSession$();
  }

  intercept(
    request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


      this.usuarioToken$.pipe().subscribe((usuario: any) => {
        this.token = usuario.token;
      });

      if (this.excluirRutas(request.url)) {
        return next.handle(request);
      }
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.token,
        },
      });

      return next.handle(request);
  }

  private excluirRutas(url: string): boolean {
    let result = false;
    environment.blacklistedRoutes.forEach((item) => {
      if (url.includes(item)) {
        result = true;
        return result;
      }
    });
    return result;
  }
}
