<!--Movil-->
<div class="footer contenedor">
    <!--COM Redes sociales -->
    <div class="footer__contenedorRedesSociales ">
        <div class="footer__contenedorLogo">
            <div class="footer__contenedorRedes">
                <div class="footer__whatsapp" matTooltip="Ir a Whatsapp" matTooltipPosition="above" aria-label="Link que permite redireccionar a Whatsapp">
                    <a target="_blank" href="https://wa.link/tajbk0">
                        <img class="footer__iconWhatsapp" src="../../../../assets/imagenes/footer/media/002-whatsapp.svg" alt="whatsapp">
                    </a>
                </div>
                <div class="footer__facebook" matTooltip="Ir a Facebook" matTooltipPosition="above" aria-label="Link que permite redireccionar a Facebook">
                    <a target="_blank" href="https://www.facebook.com/TODO-MÁS-108727547932957">
                        <img class="footer__iconFacebook" src="../../../../assets/imagenes/footer/media/005-facebook.svg" alt="facebook">
                    </a>
                </div>
                <div class="footer__instagram" matTooltip="Ir a Instragram" matTooltipPosition="above" aria-label="Link que permite redireccionar a Instagram">
                    <a target="_blank" href="https://www.instagram.com/todomas_rd/">
                        <img class="footer__iconInstagram" href="https://www.instagram.com/todomas_rd/" src="../../../../assets/imagenes/footer/media/006-instagram.svg" alt="instagram">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
