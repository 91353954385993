import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EmpresaDto } from '../data/EmpresaDto';

@Injectable({
  providedIn: 'root',
})
export class SubjectEmpresaService {
  private empresasSource$ = new BehaviorSubject<EmpresaDto[]>([]);
  private empresaToEdit$ = new BehaviorSubject<EmpresaDto>(new EmpresaDto());
  private empresaToDisable$ = new BehaviorSubject<EmpresaDto>(null);

  public set setEmpresas(empresas: EmpresaDto[]) {
    this.empresasSource$.next(empresas);
  }

  public get getEmpresas(): Observable<EmpresaDto[]> {
    return this.empresasSource$.asObservable();
  }

  public set setEmpresaToEdit(empresaToEdit){
    this.empresaToEdit$.next(empresaToEdit);
  }

  public get getEmpresaToEdit(){
    return this.empresaToEdit$.asObservable();
  }

  public set setEmpresaToDisable(empresaToDisable){
    this.empresaToDisable$.next(empresaToDisable);
  }

  public get getEmpresaToDisable(){
    return this.empresaToDisable$.asObservable();
  }
}
