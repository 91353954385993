import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Base64Service } from 'src/app/core/services/base64.service';
import { SubjectsService } from 'src/app/core/services/subjects.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-visualizador-pdf',
  templateUrl: './visualizador-pdf.component.html',
  styleUrls: ['./visualizador-pdf.component.css']
})
export class VisualizadorPdfComponent implements OnInit, OnChanges, OnDestroy {

  @Input() pdfViewer: any;

  private subs = new SubSink();
  public base64Service: Base64Service;
  public subjectServices: SubjectsService;

  public zoom = 1;
  public pdfBlob;
  public pdfSrc: any;
  public visualizarPdf: boolean;
  public esVisibleHerramientas: boolean;
  public urlBlobImprimir: string;

  constructor(private readonly injector: Injector) {
    this.base64Service = injector.get<Base64Service>(Base64Service);
    this.subjectServices = injector.get<SubjectsService>(SubjectsService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.base64ToPdf();
  }

  ngOnInit(): void {
    this.esconderPdf();
  }

  public base64ToPdf(): void {
    this.pdfBlob = this.base64Service.base64ToPdfBlob(this.pdfViewer.base64);
    if(this.pdfBlob) {
      this.visualizarPdf = true;
      this.esVisibleHerramientas = true;
    }
  }

  public descargarPdf(): void {
    this.urlBlobImprimir = this.pdfBlob;
    this.pdfSrc = document.createElement('a');
    this.pdfSrc.href = this.pdfBlob;
    this.pdfSrc.download = this.pdfViewer.nombreDocumento; // Establece el nombre del archivo de descarga
    this.pdfSrc.click();
    // Libera la URL de objeto después de la descarga
    URL.revokeObjectURL(this.pdfBlob);

}

public esconderPdf(): void {
  this.subs.add(this.subjectServices.esVisibleReportePdfGet.subscribe((data: boolean) => {
    this.esVisibleHerramientas = false;
    this.visualizarPdf = data;
  }));
}

public imprimirPdf(): void {
  this.base64Service.printPdf(this.pdfBlob);
}

zoomIn(): void {
if(this.zoom >= 0.5 && this.zoom < 8){
  this.zoom = this.zoom + 0.5;
}
}

zoomOut(): void {
  if(this.zoom > 0.5){
    this.zoom = this.zoom - 0.5;
  }
}
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
