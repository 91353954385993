import { ChangeDetectionStrategy, Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SubSink } from 'subsink';
import { EmpresaService } from '../../../feature/empresa/services/empresa.service';
import { SubjectEmpresaService } from '../../../feature/empresa/services/subject-empresa.service';
import { GenericDto } from 'src/app/core/data/GenericDto';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-filtro-buscar',
  templateUrl: './filtro-buscar.component.html',
  styleUrls: ['./filtro-buscar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltroBuscarComponent implements OnDestroy {
  private subs = new SubSink();
  public interval: any;
  public mostrarSpinner: boolean;

  constructor(
    private empresaService: EmpresaService,
    private subjectEmpresaService: SubjectEmpresaService,
    private cdr: ChangeDetectorRef
  ) {}

  public buscarInformacion(value: any): void {
    this.mostrarSpinner = true;

    this.subs.add(this.empresaService.obtenerEmpresas(value).subscribe( {
      next: (data) => {
        if(data.status === 200){
          this.subjectEmpresaService.setEmpresas = data.payload;
          this.mostrarSpinner = false;
          this.cdr.detectChanges();
        }
      },
      error: (error) => {
        console.log(error);
      }}
    ));
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subs.unsubscribe();
  }

}
