import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

import { ValidarUsuarioDto } from 'src/app/feature/autenticacion/dto/validarUsuarioDto';
import { GenericDto } from '../data/GenericDto';
import { constantes } from '../data/Constantes';
import { SessionDto } from '../data/SessionDto';
import { LoginDto } from '../../feature/autenticacion/dto/LoginDto';
import { ActualizarContraseñaDto } from 'src/app/feature/autenticacion/dto/ActualizarContraseñaDto';

@Injectable({
  providedIn: 'root'
})
export class LoginRequestService {

  vtaUrl = 'http://localhost:3000';

  headers = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8;', 'plataforma': 'A' }),
  };

  constructor(private http: HttpClient) { }

  public consultarInformacionUsuario(): Observable<GenericDto> {
    return this.http.get<GenericDto>(environment.api + constantes.LOGIN_DATOS_USUARIO);
  }

  public validarSessionActiva(codigoUsuario: number, session: number): Observable<any> {
    return this.http.get<any>( this.vtaUrl + constantes.VALIDAR_SESSION_ACTIVA + `/${codigoUsuario}/${session}` );
  }

  public loginAdministrativo(Login: any): Observable<GenericDto> {
    return this.http.post<any>(environment.api + constantes.LOGIN_ADMINISTRATIVO, Login, this.headers);
  }

  // COM Lgin
  public login(usuario: string, contrasena: string): Observable<GenericDto> {
    return this.http.get<GenericDto>(environment.api + constantes.LOGIN + `/${usuario}/${contrasena}`);
  }

  // COM Cerrar sesion
  public cerrarSesion(email: string): void {
    this.http.post<GenericDto>(environment.api + constantes.LOGOUT + `?principal=${email}`, this.headers);
  }

  // COM Obtener opciones usuario
  public opcionesUsuario(idUsuario: number): Observable<GenericDto> {
    return this.http.get<GenericDto>(environment.api + constantes.OPCIONESUSUARIO + `?codigoUsuario=${idUsuario}`, this.headers);
  }

}
