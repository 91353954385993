import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// eslint-disable-next-line max-len
import { NavBarComponent } from './layoutComponents/nav-bar/nav-bar.component';
import { SideBarComponent } from './layoutComponents/side-bar/side-bar.component';
import { NotFoundComponent } from './layoutComponents/not-found/not-found.component';


const routes: Routes = [
  { path: 'navbar', component: NavBarComponent },
  { path: 'sidebar', component: SideBarComponent },
  { path: 'not-found', component: NotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
