import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PipeFunction } from './core/pipes/pipe-function.pipe';
import { AuthGuard } from './core/guards/auth.guard';
import { environment } from '../environments/environment';

import { SharedModule } from './shared/shared.module';

// COM Modulos externos
import { MaterialModule } from './shared/libreriasUI/material/material.module';
import { DxButtonModule, DxContextMenuModule, DxDataGridModule } from 'devextreme-angular';
import { BlockUIModule } from 'ng-block-ui';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastNotificationsModule } from 'ngx-toast-notifications';



// COM Servicios
// import { TokenInterceptorsService } from './core/interceptores/token-interceptors.service';


// COM Componentes
import { BlockTemplateComponent } from './core/template/block-template/block-template.component';

// COM Librerias Externas
import * as CryptoJS from 'crypto-js';

// COM Store
import { ActionReducer, ActionReducerMap, MetaReducer, StoreFeatureModule, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// COM Feature's
// import * as featureMonitoreoReducer from './feature/monitoreo/store/monitoreo.reducers';
// import * as stateMonitoreoReducer from './feature/monitoreo/store/monitoreo.model';
import * as featurAutenticacionoReducer from './feature/autenticacion/store/autenticacion.reducers';
import * as stateAutenticacionReducer from './feature/autenticacion/store/autenticacion.model';

import { CacheInterceptor } from './core/interceptores/cache.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TokenInterceptorsService } from './core/interceptores/token-interceptors.service';


export interface State {
  // ADM_1002_RD: stateMonitoreoReducer.MonitoreoState;
  ADM_1010_RD: stateAutenticacionReducer.AutenticacionState;
}

const reducers: ActionReducerMap<State> = {
  // ADM_1002_RD: featureMonitoreoReducer.reducer,
  ADM_1010_RD: featurAutenticacionoReducer.reducer,

};

const keyCripto = environment.keyCryptoJS;

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function encryptKey(data: any): any {
  data = CryptoJS.AES.encrypt(data, keyCripto);
  data = data.toString();
  return data;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function decryptKey(data: any): any {
  data = CryptoJS.AES.decrypt(data, keyCripto);
  data = data.toString(CryptoJS.enc.Utf8);
  return data;
}

// COM Encriptacion Redux
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return localStorageSync(
    {
      keys: [
        {
          ADM_1002_RD:
          {
            encrypt: state => encryptKey(state),
            decrypt: state => decryptKey(state)
          }
        },
        {
          ADM_1008_RD:
          {
            encrypt: state => encryptKey(state),
            decrypt: state => decryptKey(state)
          }
        },
        {
          ADM_1010_RD:
          {
            encrypt: state => encryptKey(state),
            decrypt: state => decryptKey(state)
          }
        }
      ],
      rehydrate: true
    })(reducer);
}
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  return function(state, action) {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [debug, localStorageSyncReducer] : [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent,
    BlockTemplateComponent,
    PipeFunction,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    MaterialModule,
    DxDataGridModule,
    BlockUIModule.forRoot(),
    DxButtonModule,
    DxContextMenuModule,
    StoreModule.forRoot(reducers,
      { metaReducers }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    NgbModule,
    ToastNotificationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  entryComponents: [
    BlockTemplateComponent,
  ],
  exports:
    [
      PipeFunction,
    ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorsService, multi: true },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}

