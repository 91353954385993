import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { SubjectsService } from './core/services/subjects.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { Router } from '@angular/router';
import { AutenticacionStoreService } from './feature/autenticacion/store/autenticacion-store.service';
import { UsuarioDto } from './feature/autenticacion/data/UsuarioDto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'administrativoTodoMas';
  public resize: number;
  public visible: boolean;

  constructor(
    @Inject(Title) private readonly tittleService: Title,
    private readonly subjectsService: SubjectsService,
    private readonly swUpdate: SwUpdate,
    private router: Router,
    private autenticacionStoreService: AutenticacionStoreService) {
    this.capturarResolucionPantalla();
    this.tittleService.setTitle('Paganar V' + environment.appVersion);
  }
  ngAfterViewInit(): void {
    const visible = setTimeout(() => {
      this.visible = true;
      clearTimeout(visible);
    }, 300);
  }

  ngOnInit(): void {
    this.visible = false;
    this.actualizarServiceWorker();
  }

  public capturarResolucionPantalla(): void {
    this.resize = window.innerWidth;
    this.subjectsService.resolucionPantallaData = this.resize;
  }

  public actualizarServiceWorker(): void {
    this.swUpdate.available.subscribe(value => {
      window.location.reload();
    });
  }
}
