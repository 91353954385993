import { constantes } from 'src/app/core/data/Constantes';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class Base64Service {

  public base64Rejex = constantes.REGEX_VALIDAR_CADENA_BASE64;

  public pdfSrc = '';

  constructor( private http: HttpClient, private sanitizer: DomSanitizer ) { }

  // COM Imprimir PDF ****************************************************
  public printPdf(b64Data?: string): void {
    const newWindow = window.open(b64Data, '_blank');
    newWindow.onload = () => newWindow.print();

    // const byteCharacters = atob(b64Data);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const file = new Blob([byteArray], { type: constantes.MIME_PDF_3 });
    // const fileURL = URL.createObjectURL(file);
    // window.open(fileURL);
  }


  // COM Descargar PDF **************************************************
  public downloadPdf2(base64?: string, fileName?: string): void {

    let source = null;
    const browser = this.getBrowserName();

    if (browser === 'safari') {
      source = `${constantes.MIME_PDF_2},${base64}`;
    } else {
      source = `${constantes.MIME_PDF_2},${base64}`;
    }

    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}`;
    link.click();
  }

  //  COM Converit base64 en PDF-blob
  public base64ToPdfBlob(base64String: string){

    let pdfBlob: any;

    if(this.isBase64Valid(base64String)) {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray] , { type: 'application/pdf' });
      const pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
      // const pdfUrl = URL.createObjectURL(blob);
      pdfBlob = pdfUrl;
    }
    // return pdfBlob;
    return (pdfBlob.changingThisBreaksApplicationSecurity);
  }



  public downloadPdf(base64?: string, fileName?: string): void {

    const nav = (window.navigator as any);

    if (window.navigator && nav.msSaveOrOpenBlob) {
      // download PDF in IE
      const byteChar = atob(base64);
      const byteArray = new Array(byteChar.length);
      for (let i = 0; i < byteChar.length; i++) {
        byteArray[i] = byteChar.charCodeAt(i);
      }
      const uIntArray = new Uint8Array(byteArray);
      const blob = new Blob([uIntArray], { type: 'application/pdf' });
      nav.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
    } else {
      // Download PDF in Chrome etc.
      const source = `data:application/pdf;base64,${base64}`;
      const link = document.createElement('a');
      link.href = source;
      link.download = `${fileName}.pdf`;
      link.click();
    }
  }


  // public async downloadFile(): Promise<void> {
  //   const aux: string = 'data:' + 'application/pdf' + ';base64,';
  //   const arraybuffer = this.base64ToArrayBuffer(file.FileData.substr(aux.length));
  //   const blob = new Blob([arraybuffer], { type: 'application/pdf' });
  //   const url = URL.createObjectURL(blob);

  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = file.FileName;
  //   a.click();
  // }

  public base64ToArrayBuffer(base64: string): ArrayBuffer {
    // decode base64 string, remove space for IE compatibility
    const binaryString = window.atob(base64.replace(/\s/g, ''));
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  // COM Validador string base 64
  public isBase64Valid(base64?: string): boolean {
    const isBase64Valid = this.base64Rejex.test(base64);
    if (isBase64Valid) {
      return true;
    } else {
      return false;
    }
  }

  public validarBase64(base64: string): boolean {
    try {
      const decodedData = atob(base64);
      // Base 64 Valida
      return true;
    } catch (error) {
      // Base 64 Invalida
      return false;
    }
  }

  public getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }



  // public cargarImagenUsuario(imagen: IRifa): Observable<GenericDto> {
  //   return this.http.post(environment.api , imagen, this.respuesta)
  // }
  public cargarImagenUsuario(imagen) {
      return this.http.post(environment.api , imagen);
    }


  public extraerBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({ base: reader.result });
      };
      reader.onerror = error => {
        resolve({ base: null });
      };
    } catch (e) {
      return null;
    }
  });

}


