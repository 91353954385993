import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SubjectsService } from 'src/app/core/services/subjects.service';;

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnDestroy{

  constructor(
    private readonly router: Router, private subjectServices: SubjectsService) {
      this.subjectServices.ocultoNotFoundSet = true;
  }

  ngOnDestroy(): void {
    this.subjectServices.ocultoNotFoundSet = false;
  }

  public irInicio(): void {
    this.router.navigateByUrl('inicio');
  }
}
