<!-- <header class="header"> -->

<ng-container>
    <div class="contenedor header__contenidoHeader">
        <div class="header__logo">
            <img class="header__imagen" src="../../../../../assets/imagenes/logo/Logo nuevo color Todo+.png" alt="logo Todo mas" matTooltip="SmartPlay" matTooltipPosition="above" aria-label="Logo Todo+">
        </div>

        <div class="header__contenedorLink">
            <nav class="main-nav ">
                <div id="toggle-menu" class="toggle-menu" (click)="abrirMenu()">
                    <label for="toggle-menu-checkbox">
                        <img class="imagen" matTooltip="Men&#250;" matTooltipPosition="above" aria-label="Boton Men&#250;" src="../../../../assets/imagenes/icons/hamburguer-icon.png" alt="" />
                    </label>
                </div>
            </nav>
        </div>
    </div>
</ng-container>


<!-- <ng-container *ngIf="installEvent !== null">
    <div class="contenedor header__contenidoHeader">
        <div class="header__imagenCerrar" (click)="cerrarVentanaPWA()">
            <img src="../../../../assets/imagenes/pwa/close.png" alt="imagen cerrar" matTooltip="Instalar despu&#233;s" matTooltipPosition="above" aria-label="Boton que permite postergar la instalaci&#243;n web app">
        </div>
        <div class="header__imagenCelularDescarga">
            <img src="../../../../assets/imagenes/pwa/download.png" alt="imagen descargar" matTooltip="Web app smarthplay" matTooltipPosition="above" aria-label="Icono web app">
        </div>
        <div class="header__contenedorInformacionPWA">
            <p class="header__labelPWA">Instala nuestra web app y accede m&#225;s f&#225;cil a todas las funcionalidades </p>
        </div>
        <div class="header__contenedorBoton">
            <button class="btn header__BotonPWA" type="button" matTooltip="Instalar web app" matTooltipPosition="above" aria-label="Boton que permite la instalaci&#243;n web app" (click)="installByUser()">Instalar</button>
        </div>
    </div>
</ng-container> -->