import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public fechaActual: Date;
  public anoActual: number;
  public texto: string;

  constructor() { }

  ngOnInit(): void {
    this.texto = 'Hola';
  }

  public scroll(): void {
    window.scrollTo(0, 0);
  }

}
