<div class="informativo">


    <div class="tituloOpcion">

        <div class="opcion" routerLink="/empresa/tarifa-servicio" *ngIf="tarifaServicio">
            <div class="opcion_titulo" routerLinkActive="selectTitle" *ngIf="validarOpcion(constantesOpciones.TARIFA_SERVICIO)"><p>Tarifa servicio</p></div>
        </div>
        <div class="opcion" routerLink="/empresa/administrar-empresas" *ngIf="crearEmpresa">
            <div class="opcion_titulo" routerLinkActive="selectTitle"><p>Administrar empresas</p></div>
        </div>
        <div class="opcion" routerLink="/reportes/comision-por-remesas" *ngIf="opcionesReportes">
            <div class="opcion_titulo" routerLinkActive="selectTitle" *ngIf="validarOpcion(constantesOpciones.COMISIONES_POR_REMESAS)"><p>Comisiones por remesas</p></div>
        </div>

    </div>


</div>