import {
  Component, ElementRef, OnInit, Renderer2, ViewChild,
  AfterViewInit, OnDestroy, Injector, HostListener
} from '@angular/core';
import { Observable, Subscription, interval } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { SubSink } from 'subsink';

import { SubjectsService } from 'src/app/core/services/subjects.service';
import { AutenticacionStoreService } from '../../../feature/autenticacion/store/autenticacion-store.service';
import { UtilService } from '../../../core/services/util.service';
import { SweetAlertService } from '../../../core/services/sweet-alert.service';
import { ToastService } from '../../../core/services/toast.service';
import { constantes } from 'src/app/core/data/Constantes';
import { Store } from '@ngrx/store';
import { SessionDto } from 'src/app/core/data/SessionDto';
import { UsuarioDto } from 'src/app/feature/autenticacion/data/UsuarioDto';

const numbers = interval(59000);

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('hamburger', { static: false }) public hamburger: ElementRef;
  @ViewChild('menuActive', { static: false }) public menuActive: ElementRef;
  @ViewChild('sizeHeader', { static: false }) public sizeHeader: ElementRef;

  private subs = new SubSink();

  public contador?: number;
  public resize$?: Observable<number>;
  public resize?: number;
  public perfil$?: Observable<any>;
  public session?: UsuarioDto;
  public nombre?: string;
  public apellido?: string;
  public nombreCompleto?: string;
  public cantidadCorreos = 0;

  public sweetAlertService: SweetAlertService;
  public subjectService: SubjectsService;
  public utilService: UtilService;
  public rendere: Renderer2;
  public autenticacionStoreService: AutenticacionStoreService;
  public toastService: ToastService;
  public router: Router;
  public cdr: ChangeDetectorRef;
  public isMouseInLeftZone = false;
  public ocultoLogin = false;
  private subscription: Subscription;

  constructor(private readonly injector: Injector, private store: Store) {

    this.sweetAlertService = injector.get<SweetAlertService>(SweetAlertService);
    this.subjectService = injector.get<SubjectsService>(SubjectsService);
    this.utilService = injector.get<UtilService>(UtilService);
    this.rendere = injector.get<Renderer2>(Renderer2);
    this.rendere = injector.get<Renderer2>(Renderer2);
    this.autenticacionStoreService = injector.get<AutenticacionStoreService>(AutenticacionStoreService);
    this.toastService = injector.get<ToastService>(ToastService);
    this.router = injector.get<Router>(Router);
    this.cdr = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this.perfil$ = this.autenticacionStoreService.obtenerPerfiles$();

    this.resize$ = this.subjectService.resolucionPantalla;

    this.subscription = this.subjectService.onObtenerNombrefuncion().subscribe(() => this.obtenerSessionUsuario());
  }

  ngOnInit(): void {
    this.contador = 0;
    this.obtenerSessionUsuario();
    this.subjectService.dataOptenerSesion$.subscribe(data => data ? this.obtenerSessionUsuario() : '');
    this.obtenerResize();
    this.ocultarSidebarLogin();
    this.abrirSidebar();
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.abrirSidebar();
    this.subjectService.moveIconNavGet.subscribe(resp => {
      if(!resp){
        this.rendere.removeClass(this.menuActive.nativeElement, 'is-activeMenu');
        this.subjectService.iconSidebarSet = false;
      }else if (resp) {
        this.subjectService.iconSidebarSet = true;
        this.rendere.addClass(this.menuActive.nativeElement, 'is-activeMenu');
      }
    });
    this.validadorInicial();
    this.cdr.detectChanges();
  }

  public obtenerResize(): number {
    this.subs.add(this.resize$.subscribe((dataResize: number) => {
      this.resize = dataResize;
    }));
    return this.resize;
  }

  public obtenerSessionUsuario(): void {
    this.subs.add(this.perfil$.subscribe({
      next: (store: any) => {
        this.session = store;
        this.subjectService.ocultoNotFoundGet.subscribe(resp => {
          if(resp){
            this.ocultoLogin = true;
            this.subjectService.hideSidebarNavbarSet = true;
          }else
          if(Object.keys(store).length > 2) {
            this.subjectService.hideSidebarNavbarSet = false;
            this.ocultoLogin = false;
          }
          else {
            this.ocultoLogin = true;
            this.subjectService.hideSidebarNavbarSet = true;
          }
        });
        this.apellido = this.utilService.dividirApellido(this.session?.apellidos);
        this.nombreCompleto = this.session.nombres;
      }
    }));
  }

  public validadorInicial(): void {
    const stateSidebar = localStorage.getItem('stateSidebar');
    this.contador = stateSidebar === 'true' || this.obtenerResize() <= 1080 ? 1 : 0;

    if (this.contador === 0) {
      this.rendere.addClass(this.hamburger.nativeElement, 'is-active');
      this.rendere.addClass(this.menuActive.nativeElement, 'is-activeMenu');
      this.subjectService.listadoRecursosAsideData = false;
      this.contador = 0;
    }
    else if (this.contador === 1) {
      this.rendere.removeClass(this.hamburger.nativeElement, 'is-active');
      this.rendere.removeClass(this.menuActive.nativeElement, 'is-activeMenu');
      this.subjectService.listadoRecursosAsideData = true;
      this.contador = 1;
    }
  }

  public abrirSidebar(): void {
    if (this.contador === 0) {
      this.contador = 1;
      this.subjectService.listadoRecursosAsideData = true;
    }
    else if (this.contador === 1) {
      this.contador = 0;
      this.subjectService.listadoRecursosAsideData = false;
    }
    this.subjectService.listadoRecursosAside.subscribe(resp => {
      if(resp){
        this.contador = 1;
        this.rendere.removeClass(this.menuActive.nativeElement, 'is-activeMenu');
        this.rendere.removeClass(this.hamburger.nativeElement, 'is-active');
        this.subjectService.iconSidebarSet = false;
      }else {
        this.contador = 0;
        this.subjectService.iconSidebarSet = true;
        this.rendere.addClass(this.menuActive.nativeElement, 'is-activeMenu');
        this.rendere.addClass(this.hamburger.nativeElement, 'is-active');
      }
    });
  }

  public cerrarSession(): void {
    this.sweetAlertService.sweetAlertCierreSesion(this.session.email);
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (event.clientX < 75 && this.contador === 1) {
      if (!this.isMouseInLeftZone) {
        localStorage.setItem('stateSidebar', 'true');
        this.isMouseInLeftZone = true;
        this.subjectService.listadoRecursosAsideData = false;
        this.rendere.addClass(this.menuActive.nativeElement, 'is-activeMenu');
      }
    } else if(event.clientX > 342){
      if (this.isMouseInLeftZone || localStorage.getItem('stateSidebar') === 'true') {
        localStorage.setItem('stateSidebar', 'false');
        this.isMouseInLeftZone = false;
        this.subjectService.listadoRecursosAsideData = true;
        this.rendere.removeClass(this.menuActive.nativeElement, 'is-activeMenu');
      }
    }
  }

  ocultarSidebarLogin(): Promise<boolean> {
    return new Promise(res => {
      this.subjectService.ocultarSidebarLoginGet.subscribe(respuesta => respuesta ? this.ocultoLogin = true : this.ocultoLogin = false);
      this.obtenerSessionUsuario();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
