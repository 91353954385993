import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OpcionesPerfilRolDto } from 'src/app/core/data/OpcionesPerfilRolDto';
import { opciones } from 'src/app/core/data/rolOpciones';
import { AutenticacionStoreService } from 'src/app/feature/autenticacion/store/autenticacion-store.service';

@Component({
  selector: 'app-titulo-informativo-opciones',
  templateUrl: './titulo-informativo-opciones.component.html',
  styleUrls: ['./titulo-informativo-opciones.component.css']
})
export class TituloInformativoOpcionesComponent implements OnInit {


  // COM Roles perfil
  public opcionesUsuario$: Observable<any>;
  public opcionesUsuario: any;
  public constantesOpciones = opciones;

  // COM Estado de opciones
  public opcionesEmpresa = false;
  public tarifaServicio = false;
  public crearEmpresa = false;

  public opcionesReportes = false;
  public comisioneRemesas = false;

  public pathRedireccion: string;

  constructor(
    private router: Router,
    private autenticacionStoreService: AutenticacionStoreService,
    private readonly cdr: ChangeDetectorRef,
    ) {
      this.opcionesUsuario$ = this.autenticacionStoreService.obtenerOpcionesUsuario$();
      this.opcionesUsuario = new OpcionesPerfilRolDto();
    }

  ngOnInit(): void {
    this.pathRedireccion = this.router.url;
    this.validarModulos();
    this.cdr.detectChanges();
  }

  validarModulos() {
    this.cancelarModulos();
    this.cancelarVentanas();

    switch (this.pathRedireccion) {
      case '/empresa/tarifa-servicio':
        this.opcionesEmpresa = true;
        this.tarifaServicio = true;
        break;
      case '/empresa/administrar-empresas':
        this.opcionesEmpresa = true;
        this.crearEmpresa = true;
        break;
      case '/reportes/comision-por-remesas':
        this.opcionesReportes = true;
        this.comisioneRemesas = true;
        break;
    }
  }

  cancelarModulos(): void {
    this.opcionesEmpresa = false;
    this.opcionesReportes = false;
  }

  cancelarVentanas() {
    this.tarifaServicio = false;
    this.crearEmpresa = false;
    this.comisioneRemesas = false;
  }

  public validarOpcion(valor: number) {
    this.opcionesUsuario$.subscribe((data: any) => {
      this.opcionesUsuario = data;
    });
    return Object.values(this.opcionesUsuario).includes(valor);
  }
}
