export class SessionDto {

  session?: number;
  path?: string;
  estado?: boolean;
  nombre?: string;
  apellido?: string;
  codigousuario?: number;
  opcionesPerfilRolDto?: any;

  constructor(init?: Partial<SessionDto>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
